.contenedor-entrevistas {
    height: 70%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 12px #1515154D;
    opacity: 1;
    border-radius: 4px;
  }
  

.contenedor-datatable{
    height: 400px;
}

.width-search{
    width: 185px;
}

.icon-size-circle {
    font-size: 8px;
    color: #D32C2C;
    margin-right: 8px;
}

.icon-size-calendar{
    font-size: 12px;
}

.unirse{
    font-size: 11px;
    font-family: "Lato", sans-serif;
    color: #1786FC;
}

.seccion-status{
    font-size: 11px;
    font-family: "Lato", sans-serif;
}

.seccion-status__no-iniciado{
    color: #00205B;
}
.seccion-status__iniciado{
    color: #1786FC;
}
.seccion-status__cancelado{
    color: #D32C2C; 
}
.seccion-status__pospuesto{
    color: #DBBC14;
}
.seccion-status__evaluado{
    color: #C1C6C8;
}

.seccion-status__imagen{
    width: 16px;
    height: 16px;
    margin-right: 8px;
}

.table-body{
    font-size: 11px;
    font-family: "Lato", sans-serif;
    color: #333F48;
    display: flex;
    justify-content: center;
    align-items: center;
}

.table-body--blue{
    color: #1786FC;
}
.table-body--link {
    cursor: pointer;
}
.table-body--bold{
    font-weight: bold;
}
.enlace-unirse{
    cursor: pointer;
}

@media (min-width: 768px){
    .table-body__avatar{
        width: 35px;
        height: 35px;
    }
}

.table-body__avatar{
    float: left;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 5px;
    overflow: hidden;
}
.table-body__avatar>img {
    width: 100%;
    height: auto;
}

.entrevistador__name {
    overflow: hidden;
    font-size: 11px;
    line-height: 10px;
    white-space: nowrap;
    font-family: "Lato", sans-serif;
    color: #0C7BEA;
}

.sc-ezrdKe.sc-bYEvPH.gdMBZx.daSVzR{
    display: none;
}

.container-paginacion{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.custom-status__icon {
    margin-right: 8px; 
}
.custom-status__icon--reschedule {
    font-size: 14px;
    color: #DBBC14 !important;
}
.custom-status__icon--cancel {
    font-size: 16px;
    color: #D32C2C !important;
}
.custom-status__label {
    line-height: 17px;
}