.header {
  width: 100%;
  background-color: #1F489A !important;
  z-index: 1;
}

.header_logo{
  width: 140px;
  height: 25px;
}

.secciones-header{
  font-family: "Lato", sans-serif;
  font-size: 12px;
  color: #FFFFFF !important;
}

.secciones-header--bold{
  font-weight: bold;
}

.icono-usuario {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 5px;
  overflow: hidden;
}

@media (min-width: 768px) {
  .icono-usuario {
    width: 24px;
    height: 24px;
  }
}

.icono-usuario>img {
  width: 100%;
  height: auto;
}

.cursor-point{
  cursor: pointer;
}

.nombre-usuario {
  color: white;
  margin-top: 0px;
  padding-top: 0px;
}

@media (max-width: 988px) { 
  .header {
    width: 100%;
  }
  
}