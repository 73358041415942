.dashboard-interviews {
  margin: 4% auto 50px auto;
  width: 65%;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
}
.dashboard-interviews--not-compatible {
  background-color: #02205B;
}
.dashboard-interviews--compatible {
  background-color: transparent;
}
.contenedor-agenda,
.contenedor-entrevistas {
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
}
@media (max-width: 1200px) {
  .dashboard-interviews {
      width: 70%;
  }
}

.compatibility-headband {
  color: #FFFFFF;
  text-align: center;
  text-align: center;
  font: normal normal bold 15px/20px "Lato";
  font-size: 15px;
  height: 40px;
  line-height: 40px;
}

.compatibility-headband p {
  padding: 0px;
  margin: 0px;
}

.compatibility-headband a {
  text-decoration: underline;
  color: #1786FC;
}
.compatibility-headband a:hover {
  text-decoration: underline;
}