.modal-registro {
    min-height: 300px;
}

.text-bold {
    font-weight: bold !important;
}

.boton-modal-cancelar {
    width: 80px;
    height: 32px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 4px;
    opacity: 1;
}

.boton-modal-aceptar {
    width: 80px;
    height: 32px;
    background: var(--unnamed-color-1786fc) 0% 0% no-repeat padding-box;
    background: #1786FC 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    color: white;
}

.foto-perfil {
    height: 100px;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% auto;
    display: block;
    margin: auto;
}

.texto-nuevo-entrevistador {
    color: #1786FC;
    font-size: 14px;
}

.icono-nuevo-entrevistador {
    font-size: 20px;
    color: #1786FC;
}

.resultado-entrevistador {
    margin-bottom: 2px;
    border-bottom: 1px solid #E8E8E8;
}

.ocultar {
    display: none;
}

.seccion-resultados-entrevistadores {
    height: 110px;
    width: 100%;
    overflow-y: scroll;
    padding-left: 0px;
    padding-right: 0px;
    /*Estilos estándar experimentales (Principalmente Firefox)*/
    scrollbar-color: rgba(0, 0, 0, .5) rgba(0, 0, 0, 0);
    scrollbar-width: thin;
}

.seccion-resultados-entrevistadores::-webkit-scrollbar {
    -webkit-appearance: none;
}

.seccion-resultados-entrevistadores::-webkit-scrollbar:vertical {
    width: 10px;
}

.seccion-resultados-entrevistadores::-webkit-scrollbar-button:increment, .seccion-resultados-entrevistadores::-webkit-scrollbar-button {
    display: none;
}

.seccion-resultados-entrevistadores::-webkit-scrollbar-thumb {
    background-color: #797979;
    border-radius: 20px;
    border: 3px solid #f1f2f3;
}

.seccion-resultados-entrevistadores::-webkit-scrollbar-track {
    border-radius: 10px;
}

.spiner-buscando {
    margin-top: 10px;
    height: 50px;
    text-align: center;
}
.label-advertencia{
    color:#ff0000;
    font-size: 14px;
}