.contenedor-no-found{
    width: 752px;
    height: 500px;
    margin-top: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 12px #00000033;
    border-radius: 10px;
    opacity: 1;
}

.div-no-found{
    width: 100%;
    height: auto;
    font-family: "Lato", sans-serif;
}

.div-no-found__imagen{
    width: 80px;
    height: 80;
    margin-bottom: 16px;
}
.div-no-found__advertencia{
    font-size: 20px;
}
.div-no-found__mensaje--Bold{
    font-weight: bold;
}

.div-no-found__mensaje{
    font-size: 16px;
}