.contenedor-principal {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contenedor-agenda {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 12px #1515154D;
  opacity: 1;
  border-radius: 4px;
}

.seccion-documentos-agendar {
  height: 120px;
  width: 100%;
  overflow-y: scroll;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 2px;
  /* float: left; */
}

@media (min-width: 1500px) {
  .seccion-documentos-agendar {
    height: 190px;
    overflow-y: hidden;
  }
}

.fuente-texto {
  font-size: 14px;
}

.container-form-agenda {
  width: 100%;
  background: #1971FF 0% 0% no-repeat padding-box;
  border-radius: 4px 4px 0px 0px;
  opacity: 1;
}

.container-form-footer {
  text-align: right;
}

.text-form-agenda {
  text-align: left;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  font-size: 14px;
}

.redondo {
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  /* padding-left: 5px;
  padding-top: 5px; */
  display: flex;
  justify-content: center;
}

 

.boton-cancelar {
  width: 152px;
  height: 32px;
  border: 1px solid #1A90FF;
  border-radius: 4px;
  text-align: center;
  font: normal normal bold 14px/22px Lato;
  letter-spacing: 0px;
  color: #1A90FF;
  opacity: 1;
}

.boton-agendar {
  width: 152px;
  height: 32px;
  background: var(--unnamed-color-1786fc) 0% 0% no-repeat padding-box;
  background: #1786FC 0% 0% no-repeat padding-box;
  border-radius: 4px;
  text-align: center;
  font: normal normal bold 14px/22px Lato;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  border: 0px;
}

.profile__avatar-agendar {
  float: left;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 5px;
  /* margin-top: 10px; */
  overflow: hidden;
}

@media (min-width: 768px) {
  .profile__avatar-agendar {
    width: 30px;
    height: 30px;
  }
}

.profile__avatar-agendar>img {
  width: 100%;
  height: auto;
}

.perfil-usuario>img {
  width: 100%;
  height: auto;
}

.profile__header {
  overflow: hidden;
  font-size: 14px;
  line-height: 10px;
  white-space: nowrap;
}

.text-correo {
  color: #1786FC;
  font-size: 14px;
}

.seccion-participates {
  height: 30px;
}

.nombre-entrevistador {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* width: 55px; */
  margin: auto;
}

.icono-plus {
  color: #1786FC;
  font-size: 20px;
  /* margin-left: 0px;
    padding-left: 0px; */
}

.remove-entrevistador {
  color: gray;
  font-size: 25px;
}

.etiqueta-entrevistadores {
  /* width: 120px; */
  height: 34px;
  background: #E6E9EF 0% 0% no-repeat padding-box;
  border: 2px solid #E6E9EF;
  border-radius: 16px;
  margin-right: 10px;
}

.estilo-select {
  background-color: transparent;
  border: 0;
  color: white;
  font-size: 14px;
  padding-left: 0px;
}

.div-zona-horaria {
  margin-top: 30px;
}

.seccion-switch {
  margin-top: 40px;
  color: white;
}

.switchToggle input[type=checkbox] {
  height: 0;
  width: 0;
  visibility: hidden;
  position: absolute;
}

.switchToggle label {
  cursor: pointer;
  text-indent: -9999px;
  width: 50px;
  max-width: 50px;
  height: 22px;
  background: #00000040;
  display: block;
  border-radius: 100px;
  position: relative;
}

.switchToggle label:after {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 18px;
  height: 18px;
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
}

.switchToggle input:checked+label, .switchToggle input:checked+input+label {
  background: #3e98d3;
}

.switchToggle input+label:before, .switchToggle input+input+label:before {
  padding-top: 2px;
  content: 'NO';
  position: absolute;
  top: 0px;
  left: 24px;
  width: 22px;
  height: 22px;
  border-radius: 90px;
  transition: 0.3s;
  text-indent: 0;
  color: #fff;
  font-size: 14px;
}

.switchToggle input:checked+label:before, .switchToggle input:checked+input+label:before {
  padding-top: 2px;
  content: 'SI';
  position: absolute;
  top: 0px;
  left: 10px;
  width: 22px;
  height: 22px;
  border-radius: 90px;
  transition: 0.3s;
  text-indent: 0;
  color: #fff;
  font-size: 14px;
}

.switchToggle input:checked+label:after, .switchToggle input:checked+input+label:after {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.switchToggle label:active:after {
  width: 60px;
}

/* div con scroll horizontal */

div.scrollmenu {
  overflow: auto;
  white-space: nowrap;
}

div.scrollmenu .elemento {
  display: inline-block;
  text-align: center;
  padding-bottom: 5px;
}

.scrollmenu::-webkit-scrollbar {
  -webkit-appearance: none;
}

.scrollmenu::-webkit-scrollbar-button:increment, .scrollmenu::-webkit-scrollbar-button {
  display: none;
}

.scrollmenu::-webkit-scrollbar:horizontal {
  height: 10px;
}

.scrollmenu::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
  border: 3px solid #f1f2f3;
}

.scrollmenu::-webkit-scrollbar-track {
  border-radius: 10px;
}

textarea {
  resize: none;
}

.div-prueba {
  background-color: #1971FF;
  height: 110px;
  /* width: 90%; */
}

.seccion-documentos {
  height: 110px;
  width: 100%;
  overflow-y: scroll;
  padding-left: 0px;
  padding-right: 0px;
  /*Estilos estándar experimentales (Principalmente Firefox)*/
  scrollbar-color: rgba(0, 0, 0, .5) rgba(0, 0, 0, 0);
  scrollbar-width: thin;
}

.item-documento {
  margin-bottom: 10px;
  width: 100%;
}

.documento {
  margin-bottom: 0px;
  background: #E6E9EF 0% 0% no-repeat padding-box;
  height: 22px;
}

.nombre-documentos {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 90%;
  margin: auto;
  padding-top: 2px;
  font-size: 12px;
}

.boton-eliminar {
  width: 30px;
  height: 20px;
  padding-top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 0px;
  box-shadow: 1px 1px 1px #999;
}

.boton-eliminar:hover, .boton-eliminar:focus {
  background-color: transparent;
  border: 0px;
}

.remove-documento {
  color: gray;
  font-size: 14px;
  padding-top: 5px;
}

.seccion-documentos::-webkit-scrollbar {
  -webkit-appearance: none;
}

.seccion-documentos::-webkit-scrollbar:vertical {
  width: 10px;
}

.seccion-documentos::-webkit-scrollbar-button:increment, .seccion-documentos::-webkit-scrollbar-button {
  display: none;
}

.seccion-documentos::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
  border: 3px solid #f1f2f3;
}

.seccion-documentos::-webkit-scrollbar-track {
  border-radius: 10px;
}

vr {
  display: block;
  width: 2px;
  background-color: #E6E9EF;
  position: absolute;
  top: 0;
  bottom: 0;
  margin-left: -12px;
}
.label-contador{
  padding-left: 10px;
  text-align: right;
}