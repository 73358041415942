.modal-entrevistador{
    min-height: 890px;
    width: 500px;
    font-family: "Lato", sans-serif;
}

.modal-footer__boton-cancelar{
    width: 120px;
    height: 32px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #1786FC;
    border-radius: 4px;
    opacity: 1;
    color: #1786FC;
}

.modal-footer__boton-aceptar{
    width: 120px;
    height: 32px;
    background: #1786FC 0% 0% no-repeat padding-box;
    border: 1px solid #1A90FF;
    border-radius: 4px;
    opacity: 1;
    color: white;
}

.modal-entrevistador__stars{
    text-align: center;
}

.modal-entrevistador__text-area{
    height: 96px;
    width: 440px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 4px;
    opacity: 1;
    padding-right: 5px;
    padding-left: 5px;
    font-size: 12px;
}

.class-alert{
    background-color: #E6F7FF;
    border: 1px solid #91D5FF;
}

.modal-entrevistador__text--negritas{
    font-weight: bold;
}

.titulo-modal{
    font-size: 16px;
    font-family: "Lato", sans-serif;
}

.modal-entrevistador__label--ocultar{
    display: none;
}

.modal-entrevistador__label{
    color:#ff0000
}

.modal-entrevistador__icon{
    color: #1786FC;
    font-size: 20px;
}

.modal-entrevistador__text{
    font-size: 12px;
    font-family: "Lato", sans-serif;
}

.modal-entrevistador__text--gray{
    font-size: 12px;
    color: grey;
}

.text-titulos-seccion{
    font-size: 14px;
    font-family: "Lato", sans-serif;
}
