
.avatar-user {
    width: 68px;
    height: 68px;
    border-radius: 50%;
    margin-right: 5px;
    margin-top: 0px;
    overflow: hidden;
    padding-top: 0px;
  }

  .ant-picker-now{
    display: none !important;
  }

  .contenedor-confirmacion {
    width: 65%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 12px #1515154D;
    opacity: 1;
    border-radius: 4px;
    
  }

  @media (min-width: 768px) {
    .avatar-user {
      width: 150px;
      height: 150px;
    }
  }

  .avatar-user > img {
    width: 100%;
    height: auto;
  }

  .contenedorStyle{
      width: 600px;
      min-height: 400px;
  }
  .contenedorimg{
      border-bottom: 50px;
  }
  .contenedorlabel{
      margin-top: 12px;
  }
  .center{
      text-align: center;
  }
  .font-style{
      font-size: 30px;
      font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;

  }
  .contenedor-style{
    justify-content:center ;
    align-items: center;
}

.contenedor-form {
    margin-top: 20px;
}

.motivos-propuesta{
  height: 96px;
  width: 100%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #ced4da;
  border-radius: 4px;
  opacity: 1;
  padding-right: 5px;
  padding-left: 5px;
  font-size: 14px;
}
.label-advertencia{
  color:#ff0000;
  font-size: 14px;
}
.ocultar{
  display: none;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
  border-radius: 4px;
  margin-right: 2px;
  opacity: 0.6;
  filter: invert(0.8);
}

input[type="date"]::-webkit-calendar-picker-indicator:hover {
  opacity: 1
}

.titulo-posponer{
  font-family: "Lato", sans-serif;
}

.titulo-posponer__principal{
  font-size: 21px;
  font-weight: bold;
  margin-bottom: 16px;
  margin-top: 16px;
  color: #00205B;
}
.titulo-posponer__subtitulo{
  font-size: 16px;
  color: #00205B;
}

.etiquetas-input{
  font-size: 12px;
  font-weight: bold;
}

.boton-posponer{
  width: 360px;
  height: 32px;
}

.boton-posponer__titulo{
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-weight: bold;
}

.contendor-confirmacion-posponer{
  width: 432px;
  min-height: 624px;
  box-shadow: 0px 3px 6px #1515154D;
  opacity: 1;
  border-radius: 4px;
  background-color: #FFFFFF;
}

.contendor-confirmacion-inputs{
  width: 360px;
  margin-bottom: 16px;
}

.contendor-confirmacion-inputs--colorAqua{
  background-color: #E5F4FF;
}

.alinear-centro{
  display: flex;
  justify-content: center;
}

.contendor-confirmacion-rechazo{
  width: 432px;
  min-height: 448px;
  box-shadow: 0px 3px 6px #1515154D;
  opacity: 1;
  border-radius: 4px;
  background-color: #FFFFFF;
}

.contendor-confirmacion__imagen{
  margin-top: 16px;
  width: 104px;
  height: 104px;
}